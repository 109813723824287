<template>
  <div class="row custom-cards">
    <CustomCard
      v-for="(place, index) in placesData"
      :key="index"
      :className="'col-sm-6 col-md-4 col-lg-3'"
      :title="place.placeNameCurrent"
      :description="place.fullCode"
      :imagePath="place.placeImagePath"
      :defaultImg="defaultImg"
      :index="++index + filterData.currentIndex"
    >
      <li v-if="checkPrivilege(hasEducationalScheduleTimeReport())">
        <button
          @click="
            setPlaceData(place);
            openBottomSheet('EducationalScheduleTimePlaceReportFilter');
          "
          :title="$t('Reports.educationalScheduleTimePlaceReport')"
        >
          <img src="@/assets/images/reports.svg" />
        </button>
      </li>
      <li v-if="place.isHaveChildrenStatus">
        <router-link
          :to="{
            name: 'Places',
            params: {
              parentPlaceToken: place.placeToken,
            },
          }"
          :title="$t('Places.modelName')"
        >
          <img src="@/assets/images/open.svg" />
        </router-link>
      </li>
      <li v-if="checkPrivilege(hasStorageSpacePlace())">
        <router-link
          :to="{
            name: 'StorageSpacesPlaces',
            params: {
              mainToken: place.placeToken,
            },
          }"
          :title="$t('StorageSpaces.modelName')"
        >
          <img src="@/assets/images/StorageSpaces.svg" />
        </router-link>
      </li>
      <li>
        <button
          :title="$t('info')"
          @click="
            setPlaceData(place);
            openBottomSheet('PlaceInfo');
          "
        >
          <img src="@/assets/images/info.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setPlaceData(place);
            openBottomSheet('PlaceQRCode');
          "
          :title="$t('Places.qrCode')"
        >
          <img src="@/assets/images/qr-code.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasPlaceEdit())">
        <button
          @click="
            setPlaceData(place);
            openBottomSheet('PlaceUpdate');
          "
          :title="$t('edit')"
        >
          <img src="@/assets/images/pencil.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasPlaceFinaleDelete())">
        <button
          v-b-modal.PlaceDelete
          :title="$t('delete')"
          @click="setPlaceData(place)"
        >
          <img src="@/assets/images/trash.svg" />
        </button>
      </li>
      <li>
        <button
          v-b-modal.PlaceChangeActivationType
          :title="$t('changeActivationType')"
          @click="setPlaceData(place)"
        >
          <img src="@/assets/images/changeActivationType.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setPlaceData(place);
            openBottomSheet('ActionsData');
          "
          :title="$t('actionsData.modelName')"
        >
          <img src="@/assets/images/actions-data.svg" />
        </button>
      </li>
    </CustomCard>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../utils/functions";
import {
  hasPlaceEdit,
  hasPlaceFinaleDelete,
  hasStorageSpacePlace,
  hasEducationalScheduleTimeReport,
} from "./../../../utils/privilegeHelper";
import CustomCard from "./../../../components/general/CustomCard.vue";
import generalMixin from "./../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    CustomCard,
  },
  props: ["placesData", "filterData", "defaultImg"],
  methods: {
    setPlaceData(place) {
      this.$emit("setPlaceData", place);
    },
    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
    hasPlaceEdit,
    hasPlaceFinaleDelete,
    hasStorageSpacePlace,
    hasEducationalScheduleTimeReport,
  },
};
</script>

<template>
  <div v-if="placesData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="3">{{ $t("Places.data") }}</th>
          <th rowspan="2">{{ $t("SystemComponentsHierarchies.place") }}</th>
          <th rowspan="2">{{ $t("SystemComponents.place") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(place, index) in placesData" :key="index">
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table"
              :src="fullPathFileFromServer(place.placeImagePath, defaultImg)"
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(place.fullCode) }}</td>
          <td>
            {{ isDataExist(place.placeNameCurrent) }}
          </td>
          <td>
            {{
              isDataExist(
                place.systemComponentsHierarchyData
                  .systemComponentsHierarchyNameCurrent
              )
            }}
          </td>
          <td>
            {{
              isDataExist(place.systemComponentData.systemComponentNameCurrent)
            }}
          </td>
          <td class="fmenu-item-container">
            <FloatingMenu>
              <li v-if="checkPrivilege(hasEducationalScheduleTimeReport())">
                <button
                  @click="
                    setPlaceData(place);
                    openBottomSheet('EducationalScheduleTimePlaceReportFilter');
                  "
                  :title="$t('Reports.educationalScheduleTimePlaceReport')"
                >
                  <img src="@/assets/images/reports.svg" />
                </button>
              </li>
              <li v-if="place.isHaveChildrenStatus">
                <router-link
                  :to="{
                    name: 'Places',
                    params: {
                      parentPlaceToken: place.placeToken,
                    },
                  }"
                  :title="$t('Places.modelName')"
                >
                  <img src="@/assets/images/open.svg" />
                </router-link>
              </li>
              <li v-if="checkPrivilege(hasStorageSpacePlace())">
                <router-link
                  :to="{
                    name: 'StorageSpacesPlaces',
                    params: {
                      mainToken: place.placeToken,
                    },
                  }"
                  :title="$t('StorageSpaces.modelName')"
                >
                  <img src="@/assets/images/StorageSpaces.svg" />
                </router-link>
              </li>
              <li>
                <button
                  @click="
                    setPlaceData(place);
                    openBottomSheet('PlaceInfo');
                  "
                  :title="$t('info')"
                >
                  <img src="@/assets/images/info.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setPlaceData(place);
                    openBottomSheet('PlaceQRCode');
                  "
                  :title="$t('Places.qrCode')"
                >
                  <img src="@/assets/images/qr-code.svg" />
                </button>
              </li>
              <li v-if="checkPrivilege(hasPlaceEdit())">
                <button
                  @click="
                    setPlaceData(place);
                    openBottomSheet('PlaceUpdate');
                  "
                  :title="$t('edit')"
                >
                  <img src="@/assets/images/pencil.svg" />
                </button>
              </li>
              <li v-if="checkPrivilege(hasPlaceFinaleDelete())">
                <button
                  v-b-modal.PlaceDelete
                  :title="$t('delete')"
                  @click="setPlaceData(place)"
                >
                  <img src="@/assets/images/trash.svg" />
                </button>
              </li>
              <li>
                <button
                  v-b-modal.PlaceChangeActivationType
                  :title="$t('changeActivationType')"
                  @click="setPlaceData(place)"
                >
                  <img src="@/assets/images/changeActivationType.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setPlaceData(place);
                    openBottomSheet('ActionsData');
                  "
                  :title="$t('actionsData.modelName')"
                >
                  <img src="@/assets/images/actions-data.svg" />
                </button>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../utils/functions";
import {
  hasPlaceEdit,
  hasPlaceFinaleDelete,
  hasStorageSpacePlace,
  hasEducationalScheduleTimeReport,
} from "./../../../utils/privilegeHelper";
import FloatingMenu from "./../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../utils/generalMixin";

export default {
  name: "PlacesTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: ["placesData", "filterData", "defaultImg"],
  methods: {
    setPlaceData(place) {
      this.$emit("setPlaceData", place);
    },
    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
    hasPlaceEdit,
    hasPlaceFinaleDelete,
    hasStorageSpacePlace,
    hasEducationalScheduleTimeReport,
  },
};
</script>

<template>
  <div class="">
    <DashboardNavbar
      v-on:updateViewType="updateViewType('places', $event)"
      :viewType="generalSetting.places.viewType"
      :viewTypeStatus="true"
      v-on:updateActivationTypeTokens="changeActivationType($event)"
      :activationTypeTokens="generalSetting.places.activationTypeTokens"
      :activationTypesTokensOptions="activationTypesTokensOptions"
      :activationTypeStatus="true"
      :btnAddTitle="$t('Places.add')"
      :btnAddName="'PlaceAdd'"
      :btnAddStatus="checkPrivilege(hasPlaceAdd())"
      :filterSheetName="'filterSheet'"
      :filterSheetStatus="true"
    />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <PlaceTabs />

      <template v-if="hasData">
        <PlacesTable
          v-if="generalSetting.places.viewType == VIEW_TYPES.table"
          v-on:setPlaceData="
            places.place.fillData($event);
            setFilterReport($event);
          "
          :placesData="places.placesData"
          :defaultImg="places.place.defaultImg"
          :filterData="places.filterData"
        />

        <PlacesCards
          v-else-if="generalSetting.places.viewType == VIEW_TYPES.cards"
          v-on:setPlaceData="
            places.place.fillData($event);
            setFilterReport($event);
          "
          :placesData="places.placesData"
          :defaultImg="places.place.defaultImg"
          :filterData="places.filterData"
        />
        <EducationalScheduleTimePlaceReportFilter
          :filterReport="filterReport"
          v-on:isLoading="isLoading = $event"
        />

        <ActionsData :actionsData="places.place" />
        <PlaceInfo :place="places.place" />
        <PlaceDelete :place="places.place" v-on:refresh="getAllPlaces()" />
        <PlaceChangeActivationType
          :place="places.place"
          v-on:refresh="getAllPlaces()"
        />
        <CustomBottomSheetQRCode
          :refName="'PlaceQRCode'"
          :code="places.place.fullCode"
          :codeTitle="$t('Places.code')"
          :name="places.place.placeNameCurrent"
          :nameTitle="$t('Places.name')"
          :nameIcon="'places.svg'"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="places.filterData"
        v-on:changePagination="changePagination($event)"
      />
      <PlaceFilter
        :theFilterData="places.filterData"
        v-on:search="search($event)"
      />

      <PlaceAdd :place="places.place" v-on:refresh="getAllPlaces()" />
      <PlaceUpdate :place="places.place" v-on:refresh="getAllPlaces()" />
    </div>
  </div>
</template>

<script>
import { STATUS, VIEW_TYPES } from "./../../utils/constants";
import { ACTIVATION_TYPE } from "./../../utils/constantLists";
import DashboardNavbar from "./../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../components/general/ExceptionWithImg.vue";
import ActionsData from "./../../components/general/ActionsData.vue";
import CustomBottomSheetQRCode from "./../../components/general/CustomBottomSheetQRCode.vue";
import EducationalScheduleTimePlaceReportFilter from "./../reports/components/EducationalScheduleTimePlaceReportFilter.vue";
import PlaceAdd from "./components/PlaceAdd.vue";
import PlaceTabs from "./components/PlaceTabs.vue";
import PlaceUpdate from "./components/PlaceUpdate.vue";
import PlacesCards from "./components/PlacesCards.vue";
import PlacesTable from "./components/PlacesTable.vue";
import PlaceInfo from "./components/PlaceInfo.vue";
import PlaceDelete from "./components/PlaceDelete.vue";
import PlaceChangeActivationType from "./components/PlaceChangeActivationType.vue";
import PlaceFilter from "./components/PlaceFilter.vue";
import CustomPagination from "./../../components/general/CustomPagination.vue";
import Places from "./../../models/places/Places";
import apiPlace from "./../../api/place";
import { getDialogOfActivationTypes } from "./../../utils/dialogsOfConstantsLists";
import generalMixin from "./../../utils/generalMixin";
import generalSettingsMixin from "./../../utils/generalSettingsMixin";
import { checkPrivilege } from "./../../utils/functions";
import {
  hasPlaceAdd,
  hasPlaceViewActive,
  hasPlaceViewArchive,
  hasPlaceViewBlocked,
} from "./../../utils/privilegeHelper";
import EducationalScheduleTimes from "./../../models/educational/educationalScheduleTimes/EducationalScheduleTimes";
import { FILTER_REPORT_TYPES } from "./../../utils/constants";

export default {
  mixins: [generalMixin, generalSettingsMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    CustomBottomSheetQRCode,
    EducationalScheduleTimePlaceReportFilter,
    PlaceAdd,
    PlaceTabs,
    PlaceUpdate,
    PlacesCards,
    PlacesTable,
    PlaceInfo,
    PlaceDelete,
    PlaceChangeActivationType,
    PlaceFilter,
    CustomPagination,
  },
  computed: {
    hasData() {
      return this.places.placesData.length != 0;
    },
    activationTypesTokensOptions: function () {
      let arr = [];
      if (checkPrivilege(hasPlaceViewActive())) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Active
        );
        obj.totalNumber =
          this.places.activationStatistics.totalActiveCount || 0;
        if (obj) arr.push(obj);
      }
      if (checkPrivilege(hasPlaceViewArchive())) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Archived
        );
        obj.totalNumber =
          this.places.activationStatistics.totalArchivedCount || 0;
        if (obj) arr.push(obj);
      }
      if (checkPrivilege(hasPlaceViewBlocked())) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Blocked
        );
        obj.totalNumber =
          this.places.activationStatistics.totalBlockedCount || 0;
        if (obj) arr.push(obj);
      }
      return arr;
    },
  },
  props: {
    parentPlaceToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filterReport: new EducationalScheduleTimes(FILTER_REPORT_TYPES.Place)
        .filterReport,
      places: new Places(),
      VIEW_TYPES,
      activationTypesTokenOptions: getDialogOfActivationTypes(),
    };
  },
  methods: {
    checkPrivilege,
    hasPlaceAdd,
    setFilterReport(place) {
      try {
        this.filterReport.systemComponentHierarchyTokenPlace =
          place.systemComponentsHierarchyData.systemComponentsHierarchyToken;

        this.filterReport.systemComponentTokenPlace =
          place.systemComponentData.systemComponentToken;

        this.filterReport.placeToken = place.placeToken;
      } catch (error) {
        this.filterReport.systemComponentHierarchyTokenPlace = "";

        this.filterReport.systemComponentTokenPlace = "";

        this.filterReport.placeToken = "";
      }
    },
    changePagination(pagination) {
      this.places.filterData.fillData(pagination);
      this.getAllPlaces();
    },
    changeActivationType(data) {
      this.updateActivationTypeTokens("places", data);
      this.places.filterData.activationTypeTokens = data;
      this.getAllPlaces();
    },
    search(data) {
      this.places.filterData.fillData(data);
      this.getAllPlaces();
    },
    async getAllPlaces() {
      this.isLoading = true;
      try {
        this.places.placesData = [];
        this.places.filterData.geographicalDistributionDataInclude = true;
        this.places.filterData.systemComponentDataInclude = true;
        this.places.filterData.systemComponentsHierarchyDataInclude = true;
        this.places.filterData.parentPlaceToken = this.parentPlaceToken;
        this.places.filterData.getOnlyParentPlaces = this.parentPlaceToken
          ? false
          : true;

        const response = await apiPlace.getAll(this.places.filterData);
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.places.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
  watch: {
    parentPlaceToken: function () {
      this.getAllPlaces();
    },
  },
  async created() {
    this.getAllPlaces();
  },
};
</script>
